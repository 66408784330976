// import React from 'react'

// const Header = () => {
//     const containerStyle = {
//         display:"flex",
//         justifyContent:'center',
//         alignItems:'center',
//         backgroundColor:'#fff',        
//         fontSize:'24px',
//         color:'#2563EB',
//         fontWeight: '600',
//         width: '100%',
//         height: '64px',
//         border: '1px solid #fff',
//         borderRadius: '15px',
//         // marginLeft:'113px',
//         boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
//         position:'sticky',
//         top: '0',
//         zIndex: '1000'
//       }
//   return (
//     <div style={containerStyle}>
//         SignSense
//     </div>
//   )
// }

// export default Header

// const Header = () => {
//   const containerStyle = {
//       display: "flex",
//       justifyContent: 'flex-start', // Adjusted to align items to the start (left)
//       alignItems: 'center',
//       backgroundColor: '#fff',
//       fontSize: '24px',
//       color: '#2563EB',
//       fontWeight: '600',
//       width: '100%',
//       height: '64px',
//       border: '1px solid #fff',
//       borderRadius: '15px',
//       boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
//       position: 'sticky',
//       top: '0',
//       zIndex: '1000',
//       paddingLeft: '20px' // Add padding on the left to create distance from the edge
//   }
//   return (
//       <div style={containerStyle}>
//           SignSense
//       </div>
//   )
// }

// export default Header;
// import React from 'react'

// const Header = () => {
//     const containerStyle = {
//         display: "flex",
//         justifyContent: 'space-between', // Adjust to distribute space between items
//         alignItems: 'center',
//         backgroundColor: '#fff',
//         fontSize: '24px',
//         color: '#2563EB',
//         fontWeight: '600',
//         width: '100%',
//         height: '80px', // Increased height to extend the container downwards
//         border: '1px solid #fff',
//         borderRadius: '15px',
//         boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
//         position: 'sticky',
//         top: '0',
//         zIndex: '1000',
//         paddingLeft: '20px', // Padding on the left for "SignSense"
//         paddingRight: '20px' // Padding on the right for names
//     }

//     // Style for the names to align them to the right
//     const namesStyle = {
//         color: '#2563EB', // Matching the color of "SignSense"
//         fontWeight: '600' // Matching the font weight
//     }

//     return (
//         <div style={containerStyle}>
//             SignSense
//             <div style={namesStyle}>
//                 DILLIGANESH D
//                 JAFAR SADIQ A
//             </div>
//         </div>
//     )
// }

// export default Header;
import React from 'react';

const Header = () => {
    const containerStyle = {
        display: "flex",
        justifyContent: 'space-between', // Space between the title and names
        alignItems: 'center', // Vertically center the content
        backgroundColor: '#fff',
        fontSize: '24px',
        color: '#F16E00',
        fontWeight: '600',
        width: '100%',
        height: '120px', // Adjusted height for aesthetics
        border: '1px solid #fff',
        borderRadius: '15px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        position: 'sticky',
        top: '0',
        zIndex: '1000',
        padding: '20px', // Padding around the content
    };

    const namesContainerStyle = {
        display: 'flex',
        flexDirection: 'column', // Stack names vertically
        alignItems: 'flex-end', // Align names to the right
    };

    return (
        <div style={containerStyle}>
            <div>HabeVoice</div>
            <div style={namesContainerStyle}>
            </div>
        </div>
    );
}

export default Header;
