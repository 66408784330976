import React, { useState, useEffect, useRef } from "react";
import icon from "../Assets/icon.svg";

const VideoRecorder = ({ ws, id, active }) => {
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunks = useRef([]);
  const [isRecording, setIsRecording] = useState(false);
  const btnDiv = useRef(null);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((err) => console.error("Failed to get user media", err));
    return () => stream?.getTracks().forEach((track) => track.stop());
  }, []);

  const toggleRecording = () => {
    if (isRecording) {
      // Stop recording
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = () => {
        const videoBlob = new Blob(recordedChunks.current, {
          type: "video/mp4",
        });
        const videoUrl = URL.createObjectURL(videoBlob);
        videoRef.current.srcObject = null;
        videoRef.current.src = videoUrl;
        videoRef.current.controls = true;
        const sendDataThroughWebSocket = (blob) => {
          if (ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(blob);
          } else {
            console.log(
              "WebSocket is not open. Queuing data to send when open."
            );
            ws.current.addEventListener("open", () => ws.current.send(blob), {
              once: true,
            });
          }
        };
        console.log("Recording stopped");
        btnDiv.current.style.display = "none";

        // Call the function with your videoBlob
        sendDataThroughWebSocket(videoBlob);
      };
      setIsRecording(false);
    } else {
      // Start recording
      if (!stream) return;
      recordedChunks.current = [];

      let options = { mimeType: "video/webm" };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        options = { mimeType: "video/mp4" }; // Fallback mimeType
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          options = { mimeType: "" }; // Default, browser decides
        }
      }

      try {
        mediaRecorderRef.current = new MediaRecorder(stream, options);
      } catch (e) {
        console.error("Exception while creating MediaRecorder:", e);
        return;
      }

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) recordedChunks.current.push(event.data);
      };
      mediaRecorderRef.current.start();
      console.log("Recording started");
      videoRef.current.controls = false;
      setIsRecording(true);

      mediaRecorderRef.current.onerror = (event) => {
        console.error("MediaRecorder error:", event.error);
      };
    }
  };

  // Dynamic styles based on isRecording state
  const buttonStyle = isRecording
    ? {
        color: "",
        background: "",
        borderRadius: "172px",
        width: "336px",
        height: "44px",
        fontSize: "20px",
        margin: "5px",
        border: "1px solid #F16E00",
      }
    : {
        color: "",
        background: "2563EB",
        borderRadius: "172px",
        width: "336px",
        height: "44px",
        fontSize: "20px",
        margin: "5px",
        border: "1px solid #F16E00",
      };

  return (
    <div style={{ display: "flex", flexWrap: "word-wrap" }}>
      <div>
        <img src={icon} alt="icon" width={36} height={36} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <video
          ref={videoRef}
          autoPlay
          muted
          playsInline
          style={{
            width: "340px",
            height: "260px",
            borderRadius: "16px",
            margin: "5px",
            marginBottom: "25px",
          }}
          controls={isRecording ? false : true}
        ></video>
        {active && (
          <div ref={btnDiv}>
            <button onClick={toggleRecording} style={buttonStyle}>
              {isRecording ? "End Recording" : "Start Recording"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoRecorder;
