import React from 'react'
import Header from '../Header/Header'
import icon from '../Assets/icon.svg'
import SignToText from './SignToText'


const ShopController = () => {
  return (
    <div className='mainContainer' style={{overflow:'auto', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
      <div style={{width:'100%'}}> 
        <Header />
      </div>
        <div className='shopContainer' style={{backgroundColor:'#fff', width:'100%', height:'auto', margin:'25px', padding:'10px'}}>
          <div>
            <p style={{textAlign:'center',color:'#03324F',fontSize:'16px',marginBottom:'45px'}}>Click 'Start Streaming' to speak through sign language. When finished, click 'End Streaming' to begin seamless translation.</p>
          </div>
          <div className='customerContainer' style={{marginTop:'20px', display:'flex', flexWrap:'wrap'}}>
            <div style={{width:'100%'}}>
              <SignToText />
            </div>
          </div>
        </div>
    </div>
   
    
  )
}

export default ShopController
