import React from 'react'
import home from '../Assets/home.png'
import ss1 from '../Assets/ss1.svg'
import { useNavigate } from "react-router-dom";
import './home.css'
import ShopController from './ShopController'



const Home = () => {

    const navigate = useNavigate();

    const handleStart = () => {
        navigate('/shop');
    }

  return (
    <div className='homeContainer' style={{ backgroundImage: `url(${home})`,display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around', width:'100%', height:'100vh'}}>
        <div className='h1'>
            <img src={ss1} alt='sign' width={300} height={300}/>
        </div>
        <div  className='h2'>
            <p style={{textAlign:'center', color:'#fff', fontSize:'24px', fontWeight:'500'}}>With HabeVoice unlock seamless shopping experiences!</p>
            <p style={{textAlign:'center', color:'#fff', fontSize:'24px', fontWeight:'500'}}>Click 'Get Started' now to speak, sign, and connect like never before.</p>
        </div>
        <div className='h3'>
            <button style={{textAlign:'center', color:'#F16E00', fontSize:'24px', fontWeight:'700', background:'#fff', width:'360px', height: '64px', borderRadius: '172px',animation: 'pulse 1s infinite alternate'}} onClick={handleStart}>GET STARTED</button>
        </div>   
    </div>
  )
}

export default Home