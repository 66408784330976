import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import ShopController from "./Components/ShopController";
// import Demo from "./Components/Demo";

function App() {
  return (
    <Router>  
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/shop" element={<ShopController />} />
      {/* <Route path="/demo" element={<Demo />} /> */}
      </Routes>
    </Router>
  );
}

export default App;

