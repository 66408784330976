import React from 'react';
import VideoRecorder from './VideoRecorder';
import icon from '../Assets/icon.svg';

const ChatItem = ({ item, ws }) => {
    // const determineMessage = (content) => {
    //     if (!content) {
    //       return <span className="blinking-text">Hello,How can I assist you?</span>;
    //     }

    //     switch(content) {
    //         case "Have":
    //             return "Do you have a credit card payment?";
    //         case "Milk":
    //             return "Do you have milk?";
    //         case "Thank You":
    //             return "Thank you for your support";
    //         // case "Hi":
    //         //     return "Hi, how can I help you?";
    //         case "You":
    //             return "You're next in line, please proceed with your items.";
    //         case "Do":
    //             return "Do you have any discounts on dairy products today?";
    //         default:
    //             return content;
    //     }
    // };

    if (item.type === 'text') {
      return (
        <div style={{display:'flex',  alignSelf: item.sender === 'sender' ? 'start' : 'end'}}>
          <div style={{ color: '#090914', alignSelf: item.sender === 'sender' ? 'start' : 'end', width:'100%', padding:'10px', backgroundColor:'#DBEEE7', borderRadius:'15px', marginBottom:'5px' }}>
              {item.content}
          </div>
          <img src={icon} alt="icon" width={36} height={36} style={{ margin:'7px'}} />
        </div>
      );
    } else if (item.type === 'recorder') {
      return <VideoRecorder ws={ws} id={item.id} active={item.active} />;
    }
    return null;







    // if (item.type === 'text' && item.content) {
    //     return (
    //         <div style={{ display: 'flex', alignSelf: item.sender === 'sender' ? 'start' : 'end' }}>
    //             <div style={{ color: '#090914', alignSelf: item.sender === 'sender' ? 'start' : 'end', width: '100%', padding: '10px', backgroundColor: '#DBEEE7', borderRadius: '15px', marginBottom: '5px' }}>
    //                 {item.content}
    //             </div>
    //             <img src={icon} alt="icon" width={36} height={36} style={{ margin: '7px' }} />
    //         </div>
    //     );
    // } else if (item.type === 'recorder') {
    //     return <VideoRecorder ws={ws} id={item.id} active={item.active} />;
    // }
    // return null;
};

export default ChatItem;