import React, { useState, useEffect, useRef } from "react";
import ChatItem from "./ChatItem";

const SignToText = () => {
  const [chatItems, setChatItems] = useState([
    { type: "text", sender: "receiver" },
  ]);
  const ws = useRef(null);
  const nextVideoId = useRef(0);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    ws.current = new WebSocket(apiUrl);
    ws.current.onopen = () => console.log("WebSocket opened");
    ws.current.onclose = (event) =>
      console.log("WebSocket closed", event.code, event.reason);
    // const webs = new WebSocket(apiUrl);

    ws.current.onmessage = (e) => {
      let message = e.data;
      console.log(message);
      if (message === "ready_for_new_video") {
        addNewRecorder();
      } else {
        setChatItems((prev) => [
          ...prev,
          { type: "text", content: message, sender: "receiver" },
        ]);
      }
    };

    addNewRecorder();

    return () => {
      ws.current.close();
    };
  }, []);

  const addNewRecorder = () => {
    const videoId = `video-${nextVideoId.current++}`;
    setChatItems((prev) => [
      ...prev,
      { type: "recorder", id: videoId, active: true },
    ]);
  };

  return (
    <div className="App">
      <div
        style={{
          maxHeight: "90%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {chatItems.map((item, index) => (
          <ChatItem key={index} item={item} ws={ws} />
        ))}
      </div>
    </div>
  );
};

export default SignToText;
